.container-cookies {
  display: flex;
  flex-direction: column;

  width: 50%;

  padding: 2rem;
  margin: 2rem;

  background-color: #FFFFFF;

  text-align: left;

  border: 4px solid #000000;
  border-radius: 2rem;

  font-family: "Avenir Book";
}

.container-cookies p {
  white-space: pre-wrap;
  margin: 0.5rem;
  font-size: 18px;
}

.container-cookies .container-buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}

.container-cookies button {
  margin: 0.25rem;
  padding: 1rem;
  border-radius: 1rem;
  font-size: 18px;
  cursor: pointer;
}

.container-cookies {
  font-size: 18px;
}

.container-cookies .container-buttons .button-confirm:focus {
  background-color: #DEDCD1;
}

.container-cookies .container-buttons .button-confirm {
  background-color: #D9D9D9;
  border: 1px solid #D9D9D9;
}
