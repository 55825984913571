.container-introduction {
  padding: 2rem;
  margin: 2rem;

  text-align: left;
  font-size: 14px;

  border: 2px solid #7F7F7F;
  border-radius: 2rem;
}

.container-introduction .title {
  margin-bottom: 0px;
  padding-bottom: 0px;

  font-weight: bold;
  font-size: 16px;
}

.container-introduction .title-followup {
  margin-top: 0px;
}

.container-introduction .no-margin {
  margin: 0px;
}

.container-introduction .remove-highlight {
  display: inline;
  color: #000000;
  font-weight: normal;
}

.container-introduction ol {
  margin-top: 0px;
  padding-left: 2rem;

  display: inline-block;
  color: #941100;
  font-weight: bold;
}

.container-introduction li {
  padding-left: 1rem;
}

.container-introduction p {
  margin-bottom: 1.5rem;
  font-size: 18px;
}

.container-introduction .list {
  margin-bottom: 0.5rem;
}

.container-introduction .list-green {
  color: #385723;
}

@media (max-width: 480px) {
  .container-introduction {
    margin: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
  }
}
