.container-outputvaluerepresentation {
  display: grid;
  grid-template-columns: 5% 65% 30%;
  grid-template-rows: 1rem 7.75em;
  grid-gap: 0px;

  text-align: left;
  color: black;

  width: 100%;
  height: 100%;

  border: 1px solid #F4F5F4;
}

.grid-item-outputvaluerepresentation {
  margin: 0px;
}

.container-outputvaluerepresentation .rank {
  font-weight: bold;
  grid-area: 1 / 1 / 2 / 2;
}

.container-outputvaluerepresentation .value {
  text-decoration-line: underline;
  font-weight: bold;
  grid-area: 1 / 2 / 2 / 3;
  overflow: hidden;

  margin-left: 6px;
}

.container-outputvaluerepresentation .value-text {
  word-break: break-all;
}

.container-outputvaluerepresentation .lived {
  font-weight: bold;
  font-size: 12px;
  grid-area: 1 / 3 / 2 / 4;
}

.container-outputvaluerepresentation .definition {
  margin-top: 5px;
  grid-area: 2 / 1 / 3 / 4;
  max-height: 7.5em;
  overflow: hidden;
}

p {
  font-size: 13px;
  margin: 0;
}
