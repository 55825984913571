.container-editsuperadminfloat{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;

  padding: 3rem;
}

.container-editsuperadminfloat .container-editsuperadmin {
  width: 50%;
  /* height: 100%; */

  padding: 2rem;

  text-align: left;

  background-color: #FFFFFF;

  border-radius: 2rem;
  border: 4px solid #C30025;
}

/* Text */
.container-editsuperadmin .underline {
  border-bottom: 2px solid #C30025;
}

.container-editsuperadmin .title {
  font-size: 24px;
  font-weight: bold;
  color: #C30025;
}

/* Grid Alignment */
.container-editsuperadmin .container-input {
  width: 100%;
}

.container-editsuperadmin .container-input .grid-item {
  display: grid;
  grid-template-columns: 30% 70%;

  margin: 1rem 0px;

  text-align: right;
  font-weight: bold;

  width: 100%;
}

.container-editsuperadmin .container-input .left {
  margin-right: 5px;
}

.container-editsuperadmin .container-input .right {
  margin-left: 5px;
}

/* Menu Buttons */
.container-editsuperadmin .container-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.container-buttons .button {
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  width: 8rem;

  color: #000000;
  font-size: 14px;
  font-weight: bold;

  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}

.container-buttons .save {
  background-color: #C3D69B;
  border: 1px solid #C3D69B;
}

.container-buttons .cancel {
  background-color: #D9D9D9;
  border: 1px solid #D9D9D9;
}

.container-buttons .delete {
  background-color: #D99694;
  border: 1px solid #D99694;
}
