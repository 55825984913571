.footer {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0px;

  padding: 0rem 2rem;
  margin: 0 2rem;

  border-top: 2px solid black;

  font-size: 12px;
  font-family: 'Avenir Book';
}

.footer a {
  color: #0000F0;
  text-decoration: underline;
  cursor: pointer;
}

.footer a:visited {
  color: #0000F0;
}

@media (max-width: 480px) {
  .footer {
    grid-template-rows: 3rem 2rem 2rem;
    grid-template-columns: auto;
  }

  .footer .container-link {
    display: flex;
    justify-content: space-between;

    font-size: 0.9rem;
  }
}
