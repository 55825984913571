.header-admin {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-gap: 0px;

  text-align: left;
  color: #C00000;

  padding: 0rem 2rem;
  margin: 1rem 0;

  background-color: #FFFFFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.header-admin ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: space-around;
}

.header-admin li {
  margin: 1px;
  width: 100%;
}

.header-admin .logo {
  float: left;
  width: 250px;
  margin: 0rem 4rem 0.5rem 0rem;
}

.header-admin button {
  display: inline-block;
  position: relative;

  margin: 0;
  padding: 0.5rem 1rem;
  width: 100%;
  height: 100%;

  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  font-family: "Ubuntu Regular";

  background-color: #C30025;
  border: 1px solid #C30025;
  /* box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1); */

  cursor: pointer;
}

.header-admin .selected {
  background-color: #D99694;
  border: 1px solid #D99694;
}

.header-admin .count-badge {
  background-color: #000000;
  border-radius: 1.2em;
  border: 1px solid #000000;
  color: #FFFFFF;

  font-size: 12px;

  position: absolute;
  top: -0.6em;
  right: -0.6em;

  width: 1.2em;
  height: 1.2em;

  z-index: 50;
}
