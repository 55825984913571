.container-loadingfloat {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 110;
}

.loader {
  border: 16px solid #A3A3A3; /* Light grey */
  border-top: 16px solid #000000; /* Blue */
  border-radius: 50%;
  width: 25vh;
  height: 25vh;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
