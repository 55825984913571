.container-account {
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Avenir Book";

  width: 100%;
  height: 100%;
}

.container-account * {
  font-family: inherit;
}


.container-account .container-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}

.container-account .button-account {
  margin: 0px 1rem;
  padding:  0.5rem 1rem;
  width: 8rem;

  color: #000000;
  font-size: 14px;
  font-weight: bold;

  background-color: #EEECE1;
  border: 1px solid #7F7F7F;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}

.container-account .button-account:focus {
  background-color: #DEDCD1;
}
