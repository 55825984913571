.container-introductionidentifying {
  padding: 2rem;
  margin: 2rem;

  text-align: left;
  font-size: 14px;

  border: 2px solid #7F7F7F;
  border-radius: 2rem;
}

.container-introductionidentifying .title {
  margin-bottom: 1.5rem;
  padding-bottom: 0px;

  font-weight: bold;
  font-size: 20px;
  color: #385723;
}

.container-introductionidentifying p {
  margin-bottom: 1.5rem;
  font-size: 18px;
}

.container-introductionidentifying .indented {
  margin-left: 4rem;
}

.container-introductionidentifying .no-bottom {
  margin-bottom: 0rem;
}

.container-introductionidentifying .no-margin {
  margin: 0px;
}

.container-introductionidentifying .highlight {
  display: inline-block;
  color: #941100;
  font-weight: bold;
}

.container-introductionidentifying .container-notes {
  margin-top: 0;
  margin-left: 0;
}

.container-introductionidentifying .container-notes p {
  margin: 0;
  padding: 0;
}

.container-introductionidentifying ul {
  margin: 0;
  padding-left: 2rem;
}

.container-introductionidentifying li {
  padding-left: 1rem;
  font-size: 18px;
}

@media (max-width: 480px) {
  .container-introductionidentifying {
    margin: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
  }
}
