.container-participants {
  margin: 1rem;
  padding: 1rem;
}

.container-participants .container-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2rem;
}

.container-participants .container-topbar .container-search {
  display: flex;
}

.container-participants .container-topbar .input-search {
  margin-left: 5px;

  border: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.container-participants .container-topbar button {
  padding: 0.25rem 2rem;

  font-size: 18px;
  font-weight: bold;
  color: #000000;

  background-color: #C3D69B;
  border: 1px solid #C3D69B;
  border-radius: 1rem;

  cursor: pointer;
}

.container-participants .title {
  margin: 2rem 0 1rem 0;
  font-weight: bold;
  font-size: 20px;

  text-align: left;
}

/* Participant List */
.container-participants .container-participantlist {
  display: grid;
  grid-template-columns: 25% 25% 35% 15%;
  grid-template-rows: 1.5rem auto;
  grid-gap: 0px;

  justify-content: center;
  align-items: center;
}

.container-participantlist .grid-row {
  grid-column: 1 / 5;
}

.container-participantlist .grid-item {
  display: flex;
  min-height: 1.5rem;

  padding: 5px;

  text-align: left;

  border: 1px solid #DDDDDD;
}

.container-participantlist .grid-title {
  font-weight: bold;
  height: 100%;
  margin-bottom: 1rem;
}

.container-participantlist .button-item {
  justify-content: center;
  align-items: center;
}

.container-participantlist button {
  margin: auto;
  width: 80%;

  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;

  background-color: #C30025;
  border: 1px solid #C30025;

  cursor: pointer;
}
