.container-completion {
  padding: 2rem;
  margin: 2rem;

  text-align: left;
  font-size: 14px;

  text-align: left;

  border: 2px solid #7F7F7F;
}

.container-completion .title {
  text-align: left;
  font-size: 22px;
  font-weight: bold;
  color: #385723;
}

.container-completion p {
  margin-bottom: 1.5rem;
  font-size: 18px;
}
