.container-ranking {
  padding: 2rem;
  margin: 2rem;

  text-align: left;
  font-size: 14px;

  text-align: left;

  border: 2px solid #7F7F7F;
}

.container-ranking .container-valuedefinitions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 0px;
  grid-auto-flow: column;
}

.container-ranking .title {
  margin-bottom: 0;
  padding-bottom: 0px;

  font-weight: bold;
  font-size: 20px;
  color: #385723;
}

.container-ranking p {
  font-size: 18px;
}

@media (max-width: 480px) {
  .container-ranking {
    margin: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    height: 80vh;
    overflow-y: scroll;
  }

  .container-ranking .container-valuedefinitions {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(10, 1fr);
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
      width: 24px;
  }
  ::-webkit-scrollbar:horizontal {
      height: 24px;
  }
  ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, .5);
      border-radius: 10px;
      border: 2px solid #ffffff;
  }
  ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
  }
}
