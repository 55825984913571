.container-superadminevents {
  margin: 1rem;
  padding: 1rem;
}

.container-superadminevents .container-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2rem;
}

.container-superadminevents .container-topbar .container-search {
  display: flex;
}

.container-superadminevents .container-topbar button {
  padding: 0.25rem 2rem;
  margin: 0 1rem;

  font-size: 18px;
  font-weight: bold;
  color: #000000;

  background-color: #C3D69B;
  border: 1px solid #C3D69B;
  border-radius: 1rem;

  cursor: pointer;
}

.container-superadminevents .input-search {
  margin-left: 5px;

  border: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.container-superadminevents .container-eventlist {
  display: grid;
  grid-template-columns: repeat(4, 15%) 7.5% repeat(2, 1fr) 10%;
  grid-template-rows: 1.5rem auto;
  grid-gap: 0px;

  justify-content: center;
  align-items: center;
}

.container-superadminevents .grid-row {
  grid-column: 1 / 8;
}

.container-superadminevents .grid-item {
  display: flex;
  min-height: 1.5rem;

  padding: 5px;

  text-align: left;

  border: 1px solid #DDDDDD;
}

.container-superadminevents .grid-title {
  font-weight: bold;
  height: 100%;
  margin-bottom: 1rem;
}

.container-superadminevents .container-eventlist .button-item {
  justify-content: center;
  align-items: center;
}

.container-superadminevents .container-eventlist button {
  margin: auto;
  width: 80%;

  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;

  background-color: #C30025;
  border: 1px solid #C30025;

  cursor: pointer;
}

.container-superadminevents .container-uploadedlist {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(4, 15.625%) repeat(3, 12.5%);
  grid-template-rows: 1.5rem auto;
  grid-gap: 0px;

  justify-content: center;
  align-items: center;
}

.container-superadminevents .title {
  margin-top: 2rem;
  font-weight: bold;
  font-size: 20px;

  text-align: left;
}

.container-uploadedlist button {
  margin: auto;
  width: 80%;

  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;

  background-color: #C30025;
  border: 1px solid #C30025;

  cursor: pointer;
}
