.container-valuedefinition {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 5% 25% 70%;
  grid-template-rows: 25% 1fr;
  grid-gap: 0px;

  height: 85%;

  margin: 5px;
  padding: 5px;
}

.solid {
  border: 2px solid #CCCCCC;
}

.dashed {
  border: 2px dashed #BBBBBB;
}

.container-valuedefinition .grid-item {
  padding: 0px;
  margin: 5px;
}

.container-valuedefinition .rank {
  grid-area: 1 / 1 / 3 / 2;
  font-size: 16px;
}

.container-valuedefinition .value {
  grid-area: 1 / 2 / 2 / 3;
}

.container-valuedefinition .value div {
  width: 100%;
  display: inline-block;
  word-wrap: break-word;
  max-height: 2.5rem;
  overflow: hidden;
}

.container-valuedefinition .definition {
  grid-area: 1 / 3 / 3 / 4;
}

.container-valuedefinition .definition-downloading {
  font-size: 13px;
  max-height: 8em;
  overflow: hidden;
}

.container-valuedefinition .lived {
  grid-area: 2 / 2 / 3 / 3;

  margin-top: auto;
  margin-bottom: 1rem;
}

.container-valuedefinition .lived p {
  font-size: 12px;
}

.container-valuedefinition .text {
  border: none;
  width: 100%;
  height: 64px;
  resize: none;
  font-size: 16px;
}

.container-valuedefinition .input-lived {
  width: 80%;
  font-size: 16px;
}

.container-valuedefinition .input-lived-label {
  margin-bottom: 0px;
  text-align: left;
  font-size: 16px;
}

.container-valuedefinition .textarea {
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  resize: none;
  overflow: hidden;
  font-size: 16px;
}

.removeoutline * {
  border: none;
}

@media (max-width: 480px) {
  .container-valuedefinition {
    grid-auto-flow: row;
    grid-template-columns: 10% 40% 50%;
    grid-template-rows: auto 6.5rem;
  }

  .container-valuedefinition .rank {
    grid-area: 1 / 1 / 2 / 2;
  }

  .container-valuedefinition .value {
    grid-area: 1 / 3 / 2 / 4;
  }

  .container-valuedefinition .definition {
    grid-area: 2 / 1 / 3 / 4;
  }

  .container-valuedefinition .lived {
    grid-area: 1 / 2 / 2 / 3;
    margin-bottom: 0;
  }
}
