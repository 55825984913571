.container-participantaccount {
  margin: 2rem;
  padding: 2rem;

  background-color: #FFFFFF;

  border: 2px solid #C5C5C5;
  border-radius: 2rem;

  width: 50%;
}

.container-participantaccount .container-input {
  width: 100%;
}

.container-participantaccount .container-input .grid-item {
  display: grid;
  grid-template-columns: 30% 70%;

  margin: 1rem 0px;

  text-align: right;
  font-weight: bold;

  width: 100%;
}

.container-participantaccount .container-input .left {
  margin-right: 5px;
}

.container-participantaccount .container-input .right {
  margin-left: 5px;
}
