.container-live {
  font-family: 'Avenir Book';
}

.container-live * {
  font-family: inherit;
}

.container-live .live-instruction {
  font-size: 18px;
}

.container-live .container-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}

.container-live .button-live {
  margin: 0;
  padding:  0.5rem 1rem;
  width: 8rem;

  color: #898988;
  font-size: 14px;
  font-weight: bold;

  background-color: #EEECE1;
  border: 1px solid #7F7F7F;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}

.container-live .button-live:focus {
  background-color: #DEDCD1;
}

.container-live .event-info {
  /* float: right; */
}

.container-live .container-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
