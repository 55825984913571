.container-valuerepresentation {
  display: grid;
  grid-template-columns: 5% 20% 75%;
  grid-gap: 0px;

  width: 100%;
  height: 100%;

  border: 1px dashed #7F7F7F;
}

.grid-item-valuerepresentation {
  margin: 5px;
}

.container-valuerepresentation .description {
  border: 1px solid #EEEEEE;

  max-height: 7rem;
  overflow-y: auto;
}

.grid-item-valuerepresentation .value-text {
  word-break: break-all;
  width: 100%;
}

.container-valuerepresentation p {
  font-size: 16px;
}
