.container-legal {
  display: flex;
  flex-direction: column;

  padding: 2rem;
  margin: 2rem;

  text-align: left;
  font-size: 14px;

  border: 4px solid #385D8A;
  border-radius: 2rem;
}

.container-legal p {
  white-space: pre-wrap;
  margin-bottom: 1.5rem;
  font-size: 18px;
}
