.header-session {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 30% 1fr 1fr;
  grid-gap: 0px;

  text-align: left;
  color: #C00000;

  padding: 0rem 2rem;
  padding-right: 4rem;

  background-color: #DBDFD3;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.header-session h2 {
  font-family: "Ubuntu Regular";
  text-align: right;
  color: #385723;
  font-size: 24px;
}

.header-session .logo {
  float: left;
  width: 275px;
  margin: 1rem 4rem 1rem 0rem;
}

@media (max-width: 480px) {
  .header-session {
    grid-template-rows: 1fr 2rem;
    grid-template-columns: 1fr 1fr;
  }

  .header-session .container-logo {
    grid-column: 1 / 3;
  }

  .header-session .logo {
    float: none;
    max-width: 100%;
  }

  .header-session h2 {
    margin-top: -0.5rem;
    font-size: 1rem;
  }
}
