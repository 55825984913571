.App {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.container-content {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--footer-height) - 15px);
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.bold {
  font-weight: bold;
}

.italics {
  font-style: italic;
}

.underline {
  text-decoration: underline;
}

.container-float{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  padding: 3rem;
}

.container-printing {
  margin-top: 100vh;
}

@font-face {
  font-family: 'Avenir Book';
  src: local('Avenir Book'), url('./fonts/Avenir Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Ubuntu Regular';
  src: local('Ubuntu Regular'), url('./fonts/Ubuntu Regular.ttf') format('truetype');
}
