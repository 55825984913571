.container-valueselector {
  display: grid;
  grid-template-columns: 40px 1fr;
  grid-gap: 0px;

  margin: 5px;

  text-align: center;

  border: 1px solid #CCCCCC;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.container-valueselector:hover {
  transform: translateY(-2px);
}

.select-valueselector {
  border: 1px solid #CCCCCC;

  cursor: pointer;
}

.text-valueselector {
  padding: 5px 5px;
  border: 1px solid #CCCCCC;
}

.container-checkmark {
  /* width: 100%;
  height: 100%; */

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  padding: 0;
}

.container-checkmark img {
  margin: 0;
  padding: 0;
}
