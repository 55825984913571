.container-expiredevents {
  margin: 1rem;
  padding: 1rem;
}

.container-expiredevents .container-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 2rem;
}

.container-expiredevents .container-topbar button {
  padding: 0.25rem 2rem;

  font-size: 18px;
  font-weight: bold;
  color: #000000;

  background-color: #C3D69B;
  border: 1px solid #C3D69B;
  border-radius: 1rem;

  cursor: pointer;
}

.container-expiredevents .title {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 20px;

  text-align: left;
}

.container-topbar .container-search {
  display: flex;
}

.container-expiredevents .input-search {
  margin-left: 5px;

  border: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

.container-expiredevents .container-eventlist {
  display: grid;
  grid-template-columns: 20% repeat(3, 13.33%) repeat(2, 7.5%) repeat(2, 12.5%);
  grid-template-rows: 1.5rem auto;
  grid-gap: 0px;

  justify-content: center;
  align-items: center;
}

.container-expiredevents .grid-row {
  grid-column: 1 / 9;
}

.container-expiredevents .grid-item {
  display: flex;
  min-height: 1.5rem;

  padding: 5px;

  text-align: left;

  border: 1px solid #DDDDDD;
}

.container-expiredevents .grid-title {
  font-weight: bold;
  height: 100%;
  margin-bottom: 1rem;
}

.container-expiredevents .button-item {
  justify-content: center;
  align-items: center;
}

.container-eventlist button {
  margin: auto;
  width: 80%;

  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;

  background-color: #C30025;
  border: 1px solid #C30025;

  cursor: pointer;
}
