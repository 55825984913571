.container-participanthome {
  padding: 2rem;
  margin: 2rem;

  text-align: left;
  font-family: "Avenir Book";

  border: 2px solid #999933;
  border-radius: 3rem;
}

.container-participanthome .no-margin {
  margin: 0px;
}

.container-participanthome ol {
  margin-top: 0px;
  padding-left: 2rem;
}

.container-participanthome li {
  padding-left: 1rem;
}

.container-participanthome p {
  margin-bottom: 1.5rem;
  font-size: 18px;
}

.container-participant-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin: 0 4rem;
}

.container-participant-buttons .button-participant {
  margin: 0;
  padding:  0.5rem 1rem;
  width: 8rem;

  color: #898988;
  font-size: 14px;
  font-weight: bold;

  background-color: #EEECE1;
  border: 1px solid #7F7F7F;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}
