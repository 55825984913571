.container-legalfloat {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  padding: 3rem;
}

.container-legalfloat .container-legal {
  display: flex;
  flex-direction: column;

  padding: 2rem;
  margin: 2rem;

  background-color: #FFFFFF;

  text-align: left;
  font-size: 14px;

  border: 4px solid #385D8A;
  border-radius: 2rem;
}

.container-legalfloat p {
  white-space: pre-wrap;
  margin-bottom: 1.5rem;
  font-size: 18px;
}


.container-legalfloat .container-legal .container-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}

.container-legalfloat .container-legal .button-legal {
  margin: 0;
  padding:  0.5rem 1rem;
  width: 8rem;

  color: #898988;
  font-size: 14px;
  font-weight: bold;

  background-color: #EEECE1;
  border: 1px solid #7F7F7F;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}

.container-legalfloat .container-legal .button-legal:focus {
  background-color: #DEDCD1;
}
