.container-editeventfloat{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  padding: 3rem;
}

.container-editeventfloat .container-editevent {
  position: relative;
  width: 100%;
  /* height: 100%; */

  padding: 2rem;

  text-align: left;

  background-color: #FFFFFF;

  border-radius: 2rem;
  border: 4px solid #C30025;
}

.container-editevent .close {
  /* position: relative;
  right: 4.5rem;
  top: 2rem; */

  display: flex;
  justify-content: center;
  align-items: center;

  width: 2.5rem;
  height: 2.5rem;
  margin-left: auto;

  text-align: center;
  font-size: 24px;

  color: #FFFFFF;
  background-color: #C30025;

  border-radius: 1rem;
  border: 1px solid #C30025;

  cursor: pointer;
}

.container-editevent .underline {
  border-bottom: 2px solid #C30025;
}

.container-editevent .title {
  font-size: 24px;
  font-weight: bold;
  color: #C30025;
}

.container-editevent .subtitle {
  font-size: 18px;
  font-weight: bold;
  color: #C30025;
}

.container-editevent .subsubtitle {
  font-size: 15px;
  font-weight: bold;
  color: #C30025;
  text-align: center;
}

.container-editevent .container-refresh {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  right: 2rem;
  top: 2rem;

  width: 2rem;
  height: 2rem;
}

.container-editevent .container-refresh .refresh {
  margin: auto;
  width: 100%;
  height: 100%;

  cursor: pointer;
}

.container-editevent .container-refresh .refresh:focus {
  animation: spin 1.5s;
  animation-iteration-count: 1;
}

.container-editeventfloat .container-editeventcontent {
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: 16rem;
  grid-gap: 0px;

  margin: 2rem 0;
}

.container-editeventcontent .container-editeventinfo {
  padding: 1rem;

  height: 100%;

  border: 2px solid #C30025;
  border-radius: 2rem;
}

.container-editeventcontent .container-input {
  display: grid;
  grid-template-columns: 45% 55%;
  grid-gap: 0px;

  margin: 5px;

  text-align: left;
}

.container-editeventcontent .container-buttons-input {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.container-buttons-input .button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  width: 8rem;

  color: #000000;
  font-size: 14px;
  font-weight: bold;

  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}

.container-buttons-input .close-event {
  background-color: #E6B9B8;
  border: 1px solid #E6B9B8;
}

.container-input .label {
  margin-right: 10px;
}

.container-editeventcontent .container-eventparticipants {
  margin: 0px;
  margin-left: 2rem;
  padding: 1rem;

  height: 100%;

  border: 2px solid #C30025;
  border-radius: 2rem;

  overflow-y: auto;
}

.container-eventparticipants .container-participantlist {
  display: grid;
  grid-template-columns: 25% 5% 25% 15% 15% 15%;
  grid-gap: 0px;
  justify-content: center;
  align-items: center;

  margin: 5px;
}

.container-eventparticipants .container-participantlistmw {
  display: grid;
  grid-template-columns: 17.5% 13.5% 5% 25% 13% 13% 13%;
  grid-gap: 0px;
  justify-content: center;
  align-items: center;

  margin: 5px;
}

.container-eventparticipants .icon {
  width: 66%;
  margin: auto;
}

.container-eventparticipants .container-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
}

.container-eventparticipants .add-participant {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 12em;
  height: 3em;

  margin: 0 auto;
  margin-top: 1rem;

  text-align: center;
  font-size: 18px;
  font-weight: bold;

  color: #000000;
  background-color: #C3D69B;

  border-radius: 1rem;
  border: 1px solid #C3D69B;

  cursor: pointer;
}

.add-participant:focus {
  background-color: #D99694;
}

.container-eventparticipants .send-emails {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 12em;
  height: 3em;

  margin: 0 auto;
  margin-top: 1rem;

  text-align: center;
  font-size: 18px;
  font-weight: bold;

  color: #000000;
  background-color: #C3D69B;

  border-radius: 1rem;
  border: 1px solid #C3D69B;

  cursor: pointer;
}

.send-emails:focus {
  background-color: #D99694;
}

.container-eventparticipants .button-participant {
  width: 90%;

  text-align: center;
  font-size: 18px;

  color: #000000;
  /* background-color: #C30025; */
  background-color: #FFFFFF;

  border-radius: 5px;
  border: 1px solid #C30025;

  cursor: pointer;
}

.button-participant:focus {
  background-color: #D99694;
}

.button-participant:disabled {
  background-color: #DDDDDD;
  border: 1px solid #C30025;
  cursor: default;
}

.container-editevent .container-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.container-buttons .button {
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  width: 8rem;

  color: #000000;
  font-size: 14px;
  font-weight: bold;

  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}

.container-buttons .save {
  background-color: #C3D69B;
  border: 1px solid #C3D69B;
}

.container-buttons .close-event {
  background-color: #E6B9B8;
  border: 1px solid #E6B9B8;
}

.container-buttons .cancel {
  background-color: #D9D9D9;
  border: 1px solid #D9D9D9;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
