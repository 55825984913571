.container-revealing {
  padding: 2rem;
  margin: 2rem;

  text-align: left;
  font-size: 14px;

  text-align: left;

  border: 2px solid #7F7F7F;
}

/* Value Descriptions Grid */
.container-revealing .container-valuedescriptions {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto 1fr auto repeat(4, 1fr) auto 1fr;
  grid-gap: 0px;

  text-align: left;
}

.container-revealing .container-valuedescriptions .grid-item {
  justify-content: center;
  align-items: center;

  padding: 0px;
  margin: 10px;
}

.container-revealing .container-valuedescriptions .center {
  grid-column: 2 / 4;
}

.container-revealing .container-valuedescriptions .left {
  grid-column: 1 / 3;
}

.container-revealing .container-valuedescriptions .right {
  grid-column: 3 / 5;
}

/* Text stuff */
.container-revealing .container-valuedescriptions .title {
  color: #C00000;
  font-size: 18px;
  font-weight: bold;
}

.container-revealing .container-valuedescriptions .subtitle {
  color: #C00000;
  font-size: 14px;
}

.container-revealing .container-valuedescriptions .text {
  /* margin-top: auto; */
  margin-bottom: 0px;
}
