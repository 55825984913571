.container-editing {
  padding: 2rem;
  margin: 2rem;

  text-align: left;
  font-size: 14px;

  text-align: left;

  border: 2px solid #7F7F7F;
}

.container-editing .container-valuedefinitions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 0px;
  grid-auto-flow: column;
}
