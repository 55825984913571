.container-teamoutput {
  margin: 1rem;
  padding: 1rem;
}

.container-teamoutput .container-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-teamoutput .container-topbar .container-search {
  display: flex;


  width: 350px;
}

.container-teamoutput .container-topbar .input-search {
  margin-left: 5px;

  width: 100%;

  border: none;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
}

/* Text */
.container-teamoutput .container-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container-teamoutput .button-teamoutput {
  width: 5rem;

  font-size: 14px;
  font-weight: bold;
  color: #FFFFFF;

  background-color: #C30025;
  border: 1px solid #C30025;

  cursor: pointer;
}

.container-teamoutput .button-teamoutputall {
  width: 10rem;
  padding: 0.5rem;

  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;

  background-color: #C30025;
  border: 1px solid #C30025;

  cursor: pointer;
}

.container-teamoutput .title {
  font-weight: bold;
  font-size: 20px;
  color: #666666;
  text-decoration: underline;

  text-align: left;
}

.container-teamoutput .subtitle {
  font-weight: bold;
  font-size: 16px;
  color: #666666;

  text-align: center;
}

.container-teamoutput .subsubtitle {
  font-size: 12px;
  color: #CCCCCC;
  font-style: italic;

  text-align: center;
}

.container-teamoutput .text-info {
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 16px;
  color: #000000;

  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;

  text-align: center;
}

.container-teamoutput .name {
  font-size: 14px;
  color: #000000;
}

.container-teamoutput .driver {
  font-size: 16px;
  color: #0C850C;
}

.container-teamoutput .result {
  font-size: 16px;
  color: #661167;
}

.container-teamoutput .goto {
  font-size: 16px;
  color: #000D86;
}

.subtitle-goto {
  grid-column: 4 / 6;
}

.container-teamoutput .accelerator {
  font-size: 16px;
  color: #B33637;
}

.subtitle-accelerator {
  grid-column: 6 / 8;
}

/* Event Displaying */
.container-teamoutput .container-event {
  padding: 1rem;
}

.container-event .container-eventcontent {
  display: grid;
  grid-template-rows: 2rem 1.5rem auto;
  grid-gap: 0px;

  font-size: 14px;

  border: 2px solid #DDDDDD;
}

.container-eventcontent .container-eventrow {
  display: grid;
  grid-template-columns: 7.5% repeat(2, 15%) repeat(4, 15.625%);
  grid-gap: 0px;

  text-align: left;
}

.container-eventrow .grid-item {
  /* padding: 5px; */

  border: 2px solid #DDDDDD;
}

.container-topbar .container-headerbuttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 200px;
}

.container-teamoutput .container-refresh {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2rem;
  height: 2rem;
}

.container-teamoutput .container-refresh .refresh {
  margin: auto;
  width: 100%;
  height: 100%;

  cursor: pointer;
}

.container-teamoutput .container-refresh .refresh:focus {
  animation: spin 1.5s;
  animation-iteration-count: 1;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
