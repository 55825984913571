.container-login {
  margin: auto;
  padding: 2rem;

  font-family: 'Avenir Book';

  border: 4px solid #C30025;
  border-radius: 2rem;
}

.container-login .title {
  font-size: 24px;
  font-weight: bold;
  color: #C30025;
}

.container-login .label {
  font-size: 18px;
  color: #000000;
}

.container-login .container-inputs {
  display: grid;
  grid-template-columns: 40% 60%;
  grid-gap: 10px;

  margin: 0 1rem;
  padding: 1rem;

  text-align: right;
}

.container-login .status {
  grid-column: 1 / 3;
  text-align: center;
  color: #FF0000;
}

.container-login .container-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-login .container-buttons button {
  margin: 0;
  padding:  0.5rem 1rem;
  width: 8rem;

  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;

  background-color: #C30025;
  border: 1px solid #C30025;
  border-radius: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}

.container-login button:focus {
  background-color: #E32045;
}
