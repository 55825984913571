.container-infopopupfloat {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
/*
  display: flex;
  justify-content: center;
  align-items: flex-end; */
  z-index: 200;

  padding: 3rem;
}

.container-infopopup {
  position: absolute;
  bottom: 2rem;
  left: 0;
  right: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 2rem;
  margin: 0 35vw;

  border: 1px solid black;
  border-radius: 1rem;
  background-color: #FFFFFF;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
}

.container-infopopup .success {
  color: #00AA00;
}

.container-infopopup .failed {
  color: #FF0000;
}

@media (max-width: 550px) {
  .container-infopopup {
    margin: 0 1rem;
  }
}
