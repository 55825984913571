.container-addeventfloat{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  padding: 3rem;
}

.container-addeventfloat .container-addevent {
  width: 100%;
  /* height: 100%; */

  padding: 2rem;

  text-align: left;

  background-color: #FFFFFF;

  border-radius: 2rem;
  border: 4px solid #C30025;
}

.container-addevent .close {
  /* position: relative;
  right: 4.5rem;
  top: 2rem; */

  display: flex;
  justify-content: center;
  align-items: center;

  width: 2.5rem;
  height: 2.5rem;
  margin-left: auto;

  text-align: center;
  font-size: 24px;

  color: #FFFFFF;
  background-color: #C30025;

  border-radius: 1rem;
  border: 1px solid #C30025;

  cursor: pointer;
}

.container-addevent .underline {
  border-bottom: 2px solid #C30025;
}

.container-addevent .title {
  font-size: 24px;
  font-weight: bold;
  color: #C30025;
}

.container-addevent .subtitle {
  font-size: 18px;
  font-weight: bold;
  color: #C30025;
}

.container-addeventfloat .container-addeventcontent {
  display: grid;
  grid-template-columns: 45% 55%;
  grid-template-rows: 16rem;
  grid-gap: 0px;

  margin-top: 2rem;
  margin-bottom: 2rem;
}

.container-addeventcontent .container-addeventinfo {
  padding: 1rem;

  height: 100%;

  border: 2px solid #C30025;
  border-radius: 2rem;
}

.container-addeventcontent .container-input {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 0px;

  margin: 5px;

  text-align: right;
}

.container-input .label {
  margin-right: 10px;
}

.container-addeventcontent .container-participants {
  margin: 0 0 0 2rem;
  padding: 1rem;

  height: 100%;

  border: 2px solid #C30025;
  border-radius: 2rem;

  overflow-y: auto;
}

.container-addeventcontent .container-participants .container-participantlist {
  display: grid;
  grid-template-columns: 75% 25%;
  grid-gap: 0px;

  margin: 5px;

  /* border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); */
}

.container-participants .add-participant {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 12em;
  height: 3em;

  margin: 0 auto;
  margin-top: 1rem;

  text-align: center;
  font-size: 18px;
  font-weight: bold;

  color: #000000;
  background-color: #C3D69B;

  border-radius: 1rem;
  border: 1px solid #C3D69B;

  cursor: pointer;
}

.add-participant:focus {
  background-color: #D99694;
}

.container-participants .button-participant {
  width: 90%;

  text-align: center;
  font-size: 18px;

  color: #000000;
  /* background-color: #C30025; */
  background-color: #FFFFFF;

  border-radius: 5px;
  border: 1px solid #C30025;

  cursor: pointer;
}

.button-participant:focus {
  background-color: #D99694;
}

.container-addevent .container-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.container-buttons .button {
  margin-top: 2rem;
  padding: 0.5rem 1rem;
  width: 8rem;

  color: #000000;
  font-size: 14px;
  font-weight: bold;

  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}

.container-buttons .save {
  background-color: #C3D69B;
  border: 1px solid #C3D69B;
}

.container-buttons .close-event {
  background-color: #E6B9B8;
  border: 1px solid #E6B9B8;
}

.container-buttons .cancel {
  background-color: #D9D9D9;
  border: 1px solid #D9D9D9;
}
