.container-output2 {
  margin: 2rem auto;
  padding: 2rem;

  max-width: calc(1000px + 12rem);
}

.container-output2 .container-output2header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* align-items: baseline; */
  color: #385723;
}

.container-output2 .container-output2content {
  display: grid;
  grid-template-columns: 2rem repeat(3, 1fr) 2rem;
  grid-template-rows: 6rem repeat(4, 1fr) 1rem;
  grid-gap: 0px;
  min-height: 620px;
}

.container-output2 .container-output2content .grid-item {
  height: 100%;
  width: 100%;
  margin-top: 10px;
}

.container-output2 .container-output2content .left-col {
  grid-area: 1 / 2 / 6 / 3;
  padding: 0.5rem;
  margin-right: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  border: 2px solid #6A70B8;
  border-radius: 20px;
}

.container-output2 .container-output2content .center-col {
  grid-area: 1 / 3 / 6 / 4;
  padding: 0.5rem;
  margin: 0 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  border: 2px solid #854286;
  border-radius: 20px;
}

.container-output2 .container-output2content .right-col {
  grid-area: 1 / 4 / 6 / 5;
  padding: 0.5rem;
  margin-left: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  border: 2px solid #DF8D88;
  border-radius: 20px;
}

.container-output2 .container-output2content .left {
  grid-area: 2 / 1 / 6 / 2;

  color: #302F9D;
  font-size: 16px;
  font-weight: bold;
  font-style: italic;

  display: flex;
  justify-content: space-between;

  writing-mode: vertical-lr;

  transform: rotate(180deg);
}

.container-output2 .container-output2content .right {
  grid-area: 2 / 5 / 6 / 6;

  color: #BD150A;
  font-size: 16px;
  font-weight: bold;
  font-style: italic;

  display: flex;
  justify-content: space-between;

  writing-mode: vertical-lr;
}

.container-output2 .container-output2content .center {
  grid-area: 3 / 3 / 5 / 4 ;
}

.container-output2 .container-output2content .bottom-left {
  grid-area: 6 / 2 / 7 / 3 ;
  color: #302F9D;
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
}

.container-output2 .container-output2content .bottom-center {
  grid-area: 6 / 3 / 7 / 4 ;
  color: #681268;
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
}

.container-output2 .container-output2content .bottom-right {
  grid-area: 6 / 4 / 7 / 5 ;
  color: #BF1F14;
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
}

.container-output2 .container-output2content .graphic-img {
  /* width: 95%; */
}


.container-output2 .container-output2header .logo {
  width: 70%;
}

.container-output2 .container-header-title {
  margin-top: auto;
  margin-bottom: 0.5rem;
}

.container-output2 .container-header-title .title-name {
  font-size: 18px;
  font-weight: bold;
}

.container-output2 .container-header-title .date {
  font-size: 14px;
  color: #385723;
}

.container-output2 .title {
  color: #C00000;
  font-size: 16px;
  font-weight: bold;
}

.container-output2 .subtitle {
  color: #C00000;
  font-size: 12px;
}

.container-output2 .container-printfooter {
  margin-top: 10px;
}
