.container-prework {
  font-family: 'Avenir Book';
}

.container-prework * {
  font-family: inherit;
}

.container-prework .container-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
}

.container-prework .button-prework {
  margin: 0;
  padding:  0.5rem 1rem;
  width: 8rem;

  color: #898988;
  font-size: 14px;
  font-weight: bold;

  background-color: #EEECE1;
  border: 1px solid #7F7F7F;
  border-radius: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  cursor: pointer;
}

.container-prework .button-prework:focus {
  background-color: #DEDCD1;
}

.container-prework .prework-instruction {
  font-size: 16px;
}

@media (max-width: 480px) {
  .container-prework .container-buttons {
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  .container-prework .button-prework {
    margin: 1rem 0;
  }
}
