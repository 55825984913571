.container-preworklegal {
  display: flex;
  flex-direction: column;

  padding: 2rem;
  margin: 2rem;

  text-align: left;
  font-size: 14px;

  border: 4px solid #385D8A;
  border-radius: 2rem;
}

.container-preworklegal p {
  white-space: pre-wrap;
  margin-bottom: 1.5rem;
  font-size: 18px;
}

@media (max-width: 480px) {
  .container-preworklegal {
    margin: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
  }
}
