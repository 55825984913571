.container-participantprogress {
  display: flex;
  justify-content: space-around;
  align-items: center;

  text-align: center;

  width: 100%;
  height: 100%;
}

.container-participantprogress .complete {
  background-color: #00FF00;
  border-radius: 1rem;
  border: 1px solid black;

  color: #000000;

  width: 100%;
}

.container-participantprogress .incomplete {
  background-color: none;
  border-radius: 1rem;
  border: 1px solid black;

  color: #000000;

  width: 100%;
}
