.container-identifying {
  padding: 2rem;
  margin: 2rem;

  text-align: left;
  font-size: 14px;

  border: 2px solid #7F7F7F;
}

.container-identifying .container-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* margin-bottom: 2rem; */
}

.container-identifying .container-valuesgrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0px;
}

.container-identifying .right-align {
  text-align: right;
}

.container-identifying .title {
  margin-bottom: 0;
  padding-bottom: 0px;

  font-weight: bold;
  font-size: 20px;
  color: #385723;
}

.container-identifying p {
  font-size: 18px;
}

.container-identifying .selected-count {
  font-size: 20px;
}

@media (max-width: 1180px) {
  .container-identifying .container-valuesgrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 970px) {
  .container-identifying .container-valuesgrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 760px) {
  .container-identifying .container-valuesgrid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 550px) {
  .container-identifying {
    margin: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    height: 80vh;
    overflow-y: scroll;
  }

  .container-identifying .selected-count {
    position: sticky;
    top: 20px;
  }

  .container-identifying .container-topbar {
    flex-direction: column;
  }

  .container-identifying .container-valuesgrid {
    display: flex;
    flex-direction: column;
  }

  .container-identifying .mobile-row {
    grid-column: 1 / 3;
  }

  .container-identifying .right-align {
    text-align: center;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar:vertical {
      width: 24px;
  }
  ::-webkit-scrollbar:horizontal {
      height: 24px;
  }
  ::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, .5);
      border-radius: 10px;
      border: 2px solid #ffffff;
  }
  ::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #ffffff;
  }
}
