.container-facilitatoraccount {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 2rem;

  margin: 2rem;
  padding: 2rem;

  background-color: #FFFFFF;

  border: 2px solid #C5C5C5;
  border-radius: 2rem;

  width: 80%;
}

.container-facilitatoraccount .container-input {
  width: 100%;
}

.container-facilitatoraccount .container-portal-link {
  margin-top: 2rem;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-facilitatoraccount .container-input .grid-item {
  display: grid;
  grid-template-columns: 30% 70%;

  margin: 1rem 0px;

  text-align: right;
  font-weight: bold;

  width: 100%;
}

.container-facilitatoraccount .container-input .left {
  margin-right: 5px;
}

.container-facilitatoraccount .container-input .right {
  margin-left: 5px;
}

.container-facilitatoraccount .container-emailtemplate {
  display: flex;
  flex-direction: column;

  width: 90%;
}

.container-facilitatoraccount .container-emailtemplate .container-emailtext {
  border-radius: 1rem;
  border: 1px solid #BBBBBB;
  padding: 1rem;
}


.container-facilitatoraccount .title {
  font-weight: bold;
  font-size: 18px;
  text-align: left;

  margin-top: -1rem;
}

.container-facilitatoraccount .info {
  text-align: left;
  font-size: 14px;
  margin: 0.25rem 0;
}

.container-facilitatoraccount .container-emailtemplate textarea {
  width: 100%;
  resize: vertical;
}

.container-facilitatoraccount .container-emailtemplate input {
  width: 100%;
}
